import React from "react";
import { useTranslation } from "react-i18next";
import { PageSelectionButton } from "../PageSelectionButton/PageSelectionButton";
import { Squash as Hamburger } from "hamburger-react";

export function PageSelection(props) {
    const { t } = useTranslation();

    const [isOpen, setOpen] = React.useState(false);

    const toggleMenu = () => {
        setOpen((current) => !current);
        const scrollStyle = isOpen ? "auto" : "hidden";
        document.body.style.overflow = scrollStyle;
    };

    return (
        <div className="ml-12 h-full flex items-center lg:hidden">
            <Hamburger
                color="white"
                rounded
                size={24}
                toggled={isOpen}
                toggle={toggleMenu}
            />
            <div
                className={`absolute flex flex-col bg-teal z-10 w-max transition-all ease-in-out select-none overflow-y-auto max-h-[56rem] ${
                    isOpen
                        ? "opacity-1 translate-y-[30rem]"
                        : "opacity-0 -translate-y-full"
                }`}
            >
                <PageSelectionButton
                    onClick={toggleMenu}
                    title={t("home")}
                    target=""
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    title={t("manual")}
                    target="manual/introduction"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    className="!indent-8"
                    title={t("manual-prerequisites")}
                    target="manual/prerequisites"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    className="!indent-8"
                    title={t("manual-basics")}
                    target="manual/basics"
                />
                {/*
                <PageSelectionButton
                    onClick={toggleMenu}
                    className="!indent-8"
                    title={t("manual-forest-management-planning")}
                    target="manual/forest-management-planning"
                />
                */}
                <PageSelectionButton
                    onClick={toggleMenu}
                    className="!indent-8"
                    title={t("manual-drone-operations")}
                    target="manual/drone-operations"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    className="!indent-8"
                    title={t("manual-advanced")}
                    target="manual/advanced"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    title={t("guides")}
                    target="guides"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    title={t("api")}
                    target="api"
                />
                <PageSelectionButton
                    onClick={toggleMenu}
                    title={t("faq")}
                    target="faq"
                />
            </div>
        </div>
    );
}
