import React from "react";
import { getImage } from "../../services/ImageService";

export function PilotDownloadButton(props) {
    return (
        <span
            className={`select-none ${props.className ? props.className : ""}`}
        >
            <a
                className="h-24 w-72 flex justify-center items-center"
                href="https://2xper-bucket.s3.eu-west-1.amazonaws.com/globalforesterPilot-builds/android/release/2.0/GlobalForesterPilot-2.0.202/download.html"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="h-full pointer-events-none"
                    src={getImage("download_gf_pilot")}
                    alt=""
                />
            </a>
        </span>
    );
}
