import React from "react";
import { useTranslation } from "react-i18next";
import { MailchimpForm } from "../MailchimpForm/MailchimpForm";
import "./NewsletterSection.css";

export function NewsletterSection() {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col w-full p-16 md:p-24 md:px-48 bg-blue">
            <div className="max-w-screen-lg self-center text-white">
                <h2 className="font-bold mt-0">
                    {t("newsletter-section-title")}
                </h2>
                <p>{t("newsletter-section-text")}</p>
                <MailchimpForm />
            </div>
        </div>
    );
}
