const droneOperations = "manual/drone-operations";
const basics = "manual/basics";
const forestManagementPlanning = "manual/forest-management-planning";
const advanced = "manual/advanced";
const prerequisites = "manual/prerequisites";
const guides = "guides";
const faq = "faq";
const api = "api";

const references = {
    Drönaroperationer: droneOperations + "#dronaroperationer",
    "Rapid Orthophoto": droneOperations + "#rapid-orthophotos",
    "Rapid Orthophotos": droneOperations + "#rapid-orthophotos",
    "Drone Mission": droneOperations + "#drone-missions",
    "Drone Missions": droneOperations + "#drone-missions",
    "Drone Operations": droneOperations + "#drone-operations",
    "Drone Free Flight": droneOperations + "#drone-free-flight",
    "Free Flight": droneOperations + "#drone-free-flight",
    Quick: droneOperations + "#quick",
    Balanced: droneOperations + "#balanced",
    Pretty: droneOperations + "#pretty",
    Sparse: droneOperations + "#sparse",
    Dense: droneOperations + "#dense",
    Drönaruppdrag: droneOperations + "#dronaruppdrag",
    Drönaruppdraget: droneOperations + "#dronaruppdrag",
    Drönaruppdragets: droneOperations + "#dronaruppdrag",
    Drönaruppdragen: droneOperations + "#dronaruppdrag",
    Snabbortofoto: droneOperations + "#snabbortofoton",
    Snabbortofotos: droneOperations + "#snabbortofoton",
    Snabbortofoton: droneOperations + "#snabbortofoton",
    Snabbortofotot: droneOperations + "#snabbortofoton",
    Snabbortofotona: droneOperations + "#snabbortofoton",
    Snabb: droneOperations + "#snabb",
    Balanserad: droneOperations + "#balanserad",
    Snygg: droneOperations + "#snygg",
    Gles: droneOperations + "#gles",
    Tät: droneOperations + "#tat",
    Markföljning: droneOperations + "#markfoljning",
    "Friflygning med drönare": droneOperations + "#friflygning-med-dronare",
    Friflygning: droneOperations + "#friflygning-med-dronare",
    Uppdragsparametrar: droneOperations + "#uppdragsparametrar",
    "Managed Areas": basics + "#managed-areas",
    "Managed Area": basics + "#managed-areas",
    Geometriredigeraren: basics + "#geometriredigeraren",
    Geometriredigerarens: basics + "#geometriredigeraren",
    "Geometry Editor": basics + "#the-geometry-editor",
    Områden: basics + "#omraden",
    Område: basics + "#omraden",
    Området: basics + "#omraden",
    Spårlogg: basics + "#sparloggar",
    Spårloggen: basics + "#sparloggar",
    Spårloggar: basics + "#sparloggar",
    Tracklog: basics + "#tracklogs",
    Tracklogs: basics + "#tracklogs",
    Arbetsgrupp: basics + "#arbetsgrupper",
    Arbetsgrupper: basics + "#arbetsgrupper",
    Arbetsgruppen: basics + "#arbetsgrupper",
    "Mina arbetsgrupper": basics + "#mina-arbetsgrupper",
    Teams: basics + "#teams",
    Team: basics + "#teams",
    "My Teams": basics + "#my-teams",
    "Offline Availability": basics + "#offline-availability",
    "Terrain Tracking": droneOperations + "#terrain-tracking",
    Project: basics + "#projects",
    Projects: basics + "#projects",
    Projekt: basics + "#projekt",
    Projektet: basics + "#projekt",
    Projekten: basics + "#projekt",
    "Point Observations": basics + "#observations",
    "Point Observation": basics + "#observations",
    "Area Observations": basics + "#observations",
    "Area Observation": basics + "#observations",
    Observationer: basics + "#observationer",
    Observation: basics + "#observationer",
    Observationen: basics + "#observationer",
    Punktobservationer: basics + "#observationer",
    Punktobservation: basics + "#observationer",
    Punktobservationen: basics + "#observationer",
    Ytobservationer: basics + "#observationer",
    Ytobservation: basics + "#observationer",
    Observations: basics + "#observations",
    "The map": basics + "#the-map",
    "Map Bundles": basics + "#map-bundles",
    "Map Bundle": basics + "#map-bundles",
    "synkas det nya Snabbortofotot automatiskt och blir tillgängligt för andra användare":
        basics + "#synkronisera-dina-projektdata",
    "automatically synced and will become available to other users":
        basics + "#syncing-your-project-data",
    "Tillgänglighet offline": basics + "#tillganglighet-offline",
    "tillgängligt offline": basics + "#tillganglighet-offline",
    "tillgängliga offline": basics + "#tillganglighet-offline",
    "available offline": basics + "#offline-availability",
    Kartan: basics + "#kartan",
    Karta: basics + "#karta",
    Kartpaket: basics + "#kartpaket",
    Kartpaketet: basics + "#kartpaket",
    "Exportera Snabbortofoton som GeoPackage":
        advanced + "#exportera-snabbortofoton-som-geopackage",
    "Exporting Rapid Orthophotos as GeoPackage":
        advanced + "#exporting-rapid-orthophotos-as-geopackage",
    "Strategic surveying campaigns": guides + "#strategic-surveying-campaigns",
    "Drone Mission Parameters": droneOperations + "#drone-mission-parameters",
    "Strategiska inventeringskampanjer":
        guides + "#strategiska-inventeringskampanjer",
    "Report Error-function": basics + "#errors-and-support",
    felrapporteringsfunktionen: basics + "#fel-och-support",
    "Taktisk användning av drönare": guides + "#taktisk-anvandning-av-dronare",
    "Stöd för planläggare": guides + "#planlaggning",
    "Tactical use of drones": guides + "#tactical-use-of-drones",
    "Support for planners":
        guides + "#creating-and-updating-forest-management-plans",
    "Skapa Snabbortofoton manuellt genom att dra-och-släppa bilder i ett Projekt":
        advanced +
        "#skapa-snabbortofoton-manuellt-genom-att-dra-och-slappa-bilder-i-ett-projekt",
    "Create Rapid Orthophotos Manually by Importing Images into a Project using Drag-and-Drop":
        advanced +
        "#create-rapid-orthophotos-manually-by-importing-images-into-a-project-using-drag-and-drop",
    "Forest Management Planning":
        forestManagementPlanning + "#forest-management-planning",
    "Forest Management Planning Project":
        forestManagementPlanning + "#forest-management-planning-projects",
    "Forest Management Planning Projects":
        forestManagementPlanning + "#forest-management-planning-projects",
    Ajourhållning: forestManagementPlanning + "#ajourhallning",
    Ajourhållningsprojekt: forestManagementPlanning + "#ajourhallningsprojekt",
    Ajourhållningsprojektet:
        forestManagementPlanning + "#ajourhallningsprojekt",
    "Ajourhållningsprojektet avslutas":
        forestManagementPlanning + "#avsluta-ett-ajourhallningsprojekt",
    "Completing a Forest Management Planning Project":
        forestManagementPlanning +
        "#completing-a-forest-management-planning-project",
    Åtgärder: basics + "#atgarder",
    "Föreslagna åtgärder": basics + "#foreslagna-atgarder",
    "Genomförda åtgärder": basics + "#genomforda-atgarder",
    Åtgärd: basics + "#atgarder",
    Åtgärden: basics + "#atgarder",
    Avdelning: basics + "#avdelningar",
    Avdelningar: basics + "#avdelningar",
    Avdelningens: basics + "#avdelningar",
    Avdelnings: basics + "#avdelningar",
    Avdelningen: basics + "#avdelningar",
    "Uppdatera avdelningar":
        forestManagementPlanning + "#uppdatera-avdelningar",
    "Dela avdelningen": forestManagementPlanning + "#dela-avdelningar",
    "Slå ihop avdelningen": forestManagementPlanning + "#sla-ihop-avdelningar",
    "Updating Compartments":
        forestManagementPlanning + "#updating-compartments",
    Compartments: basics + "#compartments",
    Compartment: basics + "#compartments",
    "Importera Forestand-fil": basics + "#importera-forestand-fil",
    "Importing Forestand File": basics + "#importing-forestand-file",
    Uppgift: basics + "#uppgifter",
    Task: basics + "#tasks",
    Tasks: basics + "#tasks",
    Uppgiften: basics + "#uppgifter",
    Huvudmenyn: basics + "#huvudmenyn",
    "Main Menu": basics + "#the-main-menu",
    "splitting the Compartment":
        forestManagementPlanning + "#splitting-compartments",
    "merging the Compartment":
        forestManagementPlanning + "#merging-compartments",
    "Compartment Attributes": basics + "#compartment-attributes",
    Action: basics + "#actions",
    Actions: basics + "#actions",
    "Proposed Actions": basics + "#proposed-actions",
    Avdelningsattribut: basics + "#avdelningsattribut",
    Avdelningarna: basics + "#avdelningarna",
    "Accessing Data via WMS Service":
        advanced + "#accessing-data-via-wms-service",
    "Tillgång till data via WMS-tjänst":
        advanced + "#tillgang-till-data-via-wms-tjanst",
    "Project Type": basics + "#project-types",
    "Project Types": basics + "#project-types",
    Projekttyp: basics + "#projekttyper",
    Projekttyper: basics + "#projekttyper",
};

export function getHREF(key, location) {
    if (references[key]) {
        let baseLocation = location.split("/")[1];
        if (baseLocation === "manual") {
            return references[key].split("/")[1];
        } else {
            return references[key];
        }
    } else {
        console.debug("Missing reference for key: ", key);
    }
}
