import React from "react";
import { AppStoreDownloadButton } from "../AppStoreDownloadButton/AppStoreDownloadButton";
import { PilotDownloadButton } from "../PilotDownloadButton/PilotDownloadButton";
import { getImage } from "../../services/ImageService";
import { FeatureSummary } from "../../components/FeatureSummary/FeatureSummary";
import { FeatureSummaryItem } from "../FeatureSummaryItem/FeatureSummaryItem";
import { useTranslation } from "react-i18next";

export function Hero() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full min-h-[1200px] 2xl:justify-center relative text-white bg-gradient-to-b from-[#358A63] to-[#47AE7E]">
            <div className="flex relative h-full 2xl:justify-center">
                <div className="flex w-full justify-between p-16 mb-12 mt-24 md:p-24 md:px-48 md:mb-48 2xl:p-0 2xl:mt-48 2xl:max-w-screen-lg">
                    <div className="flex flex-col z-40 max-w-3/4">
                        <div className="text-3xl lg:text-4xl font-bold mb-2">
                            {t("gf-hero-title-1")}
                            <span className="text-pine italic ml-4">
                                {t("gf-hero-emphasis-1")}
                            </span>
                        </div>
                        <div className="text-3xl lg:text-4xl font-bold mb-12">
                            {t("gf-hero-title-2")}
                            <span className="text-pine italic ml-4">
                                {t("gf-hero-emphasis-2")}
                            </span>
                        </div>
                        <div className="text-lg mb-12">
                            {t("gf-hero-subtitle")}
                        </div>
                        <div className="flex">
                            <AppStoreDownloadButton />
                            <PilotDownloadButton className="ml-8" />
                        </div>
                    </div>
                    <img
                        className="z-40 max-w-1/4 ml-24 hidden lg:block"
                        src={getImage("gf_icon_white_compact")}
                        alt=""
                    />
                </div>
                <img
                    className="z-0 w-full absolute bottom-0 opacity-50"
                    src={getImage("mountain")}
                    alt=""
                />
                <img
                    className="z-10 w-full absolute bottom-0"
                    src={getImage("forest1")}
                    alt=""
                />
                <img
                    className="z-20 w-full absolute bottom-0"
                    src={getImage("forest0")}
                    alt=""
                />
            </div>
            <FeatureSummary
                className="bg-[#276347]"
                text={t("feature-summary-introduction")}
            >
                <FeatureSummaryItem
                    className="bg-pine"
                    title={t("feature-1-title")}
                    subtitle={t("feature-1-subtitle")}
                />
                <FeatureSummaryItem
                    className="bg-pine"
                    title={t("feature-2-title")}
                    subtitle={t("feature-2-subtitle")}
                />{" "}
                <FeatureSummaryItem
                    className="bg-pine"
                    title={t("feature-3-title")}
                    subtitle={t("feature-3-subtitle")}
                />{" "}
                <FeatureSummaryItem
                    className="bg-pine"
                    title={t("feature-4-title")}
                    subtitle={t("feature-4-subtitle")}
                />{" "}
                <FeatureSummaryItem
                    className="bg-pine col-span-2"
                    title={t("feature-5-title")}
                    subtitle={t("feature-5-subtitle")}
                />
            </FeatureSummary>
        </div>
    );
}
