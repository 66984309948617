import React from "react";
import { useTranslation } from "react-i18next";
import { NavigationLink } from "../NavigationLink/NavigationLink";

export function SubNavbar() {
    const { t } = useTranslation();

    return (
        <div className="hidden lg:flex bg-[#3A9E70] h-24 fixed mt-24 w-full 2xl:justify-center whitespace-nowrap z-10 md:px-32">
            <div className="flex h-full items-center w-[1024px] max-w-screen-lg">
                <NavigationLink
                    className="text-white w-min 2xl:pl-0"
                    title={t("manual-prerequisites")}
                    target="prerequisites"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-basics")}
                    target="basics"
                />
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-drone-operations")}
                    target="drone-operations"
                />
                {/*
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-forest-management-planning")}
                    target="forest-management-planning"
                />
            */}
                <NavigationLink
                    className="text-white w-min"
                    title={t("manual-advanced")}
                    target="advanced"
                />
            </div>
        </div>
    );
}
